const Panel = {
  $el: $('.panel'),
  init: function () {
    var _ = this

    _.$el.on('click', '.panel-header', function () {
      var $self = $(this)

      $self.next('.panel-content').slideToggle(300).parents('.panel').toggleClass('open')
    })
  }
}

export default Panel
