/* global analytics */
/* global getCookie */

const Segment = {
  // https://community.segment.com/t/63tjdd/is-analytics-track-asynchronous
  TIMEOUT: 300,

  init() {
    let _ = this;
    _.trackCTA();
    _.trackNewsletterSignup();
    _.trackLink();
  },

  segment_event(eventName, props) {
    props['page'] = window.location.pathname;
    props['zone'] = document.head.querySelector("[property~='og:region'][content]").content;
    props['locale'] = document.documentElement.lang;
    props['utm_params'] = window.utm_params;

    // Google Analytics - client_id and session_id information stored in the cookie
    let ga_client_id = getCookie('_ga')
    props['client_id'] = ga_client_id ? ga_client_id.substring(6) : '0';
    let ga_session = getCookie('_ga_' + window.ga4_meausrement_id)
    props['session_id'] = ga_session ? ga_session.substring(6, ga_session.indexOf('.', 6)) : '0';

    // external call to Segment
    analytics.track(eventName, props);
  },

  _applyFacebookCookies(props) {
    for (const cookie of ['_fbc', '_fbp']) {
      const r = getCookie(cookie);
      if (r) props[cookie] = r;
    }
  },

  trackLink() {
    let _ = this;
    $("a").not(".btn, .featured, .connect-btn").on('click', function(e){
      let target = $(this);
      e.preventDefault();

      const props = {
        link_name: target.text().trim(),
        link_start_page: window.location.pathname,
        link_end_page: target.attr("href"),
        id: target.attr('id') || target.parent().attr('class') + " > " + target.attr('class'),
      };
      let eventName = 'Link Clicked'
      _.segment_event(eventName, props);

      if(target.attr("href") !== undefined && target.attr("href") != "") {
        setTimeout(function () {
            window.location.href=target.attr("href");
        }, _.TIMEOUT);
      }

    });
  },

  trackCTA() {
    let _ = this;
    $(".btn, a.featured").not("#email-signup-btn, .connect-btn").on('click', function(e) {

      let target = $(this);
      e.preventDefault();

      const cta_name = (target.text() || target.val()).trim();
      const props = {
        cta_name: cta_name,
        cta_start_page: window.location.pathname,
        cta_end_page: target.attr("href"),
        id: target.attr('id') || target.parent().attr('class') + " > " + target.attr('class'),
      };

      let eventName = 'CTA Button Clicked'
      if(cta_name.toLowerCase() === 'build your box' || cta_name.toLowerCase() === 'order now' || cta_name.toLowerCase() === 'join') {
        eventName = 'Build your Box'
      }

      _.segment_event(eventName, props)

      if(target.attr("href") !== undefined) {
        setTimeout(function () {
            window.location.href=target.attr("href");
        }, _.TIMEOUT);
      } else if (target.attr('type') === 'submit'){
        setTimeout(function () {
            target.closest("form").submit();
        }, _.TIMEOUT);
      }

    });
  },

  trackNewsletterSignup() {
    let _ = this;
    $("#email-signup-btn").on('click', function(e) {

      let target = $(this);
      e.preventDefault();

      const cta_name = (target.text() || target.val()).trim();
      const props = {
        cta_name: cta_name,
        cta_start_page: window.location.pathname,
        cta_end_page: target.attr("href"),
        id: target.attr('id') || target.parent().attr('class') + " > " + target.attr('class'),
        email: $('#subscribe-email').val()
      };

      let eventName = 'Newsletter Signup';

      _.segment_event(eventName, props);

      if(target.attr("href") !== undefined) {
        setTimeout(function () {
            window.location.href=target.attr("href");
        }, _.TIMEOUT);
      } else if (target.attr('type') === 'submit'){
        setTimeout(function () {
            target.closest("form").submit();
        }, _.TIMEOUT);
      }

    });
  },

  promoCodeEntered(promo_code, plan_title, frequency_title) {
    const eventName = 'Promo Code Entered';
    const props = {
      promo_code: promo_code,
      plan_title: plan_title,
      frequency_title: frequency_title,
    };
    this.segment_event(eventName, props);
  },

  promoCodeDenied(promo_code, plan_title, frequency_title, reason) {
    const eventName = 'Promo Code Denied';
    const props = {
      promo_code: promo_code,
      plan_title: plan_title,
      frequency_title: frequency_title,
      reason: reason,
    };
    this.segment_event(eventName, props);
  },

  promoCodeApplied(promo_code, plan_title, frequency_title, promo_data) {
    const eventName = 'Promo Code Applied';
    const props = {
      promo_code: promo_code,
      plan_title: plan_title,
      frequency_title: frequency_title,
      promo_data: promo_data,
    };
    this.segment_event(eventName, props);
  },

  promoCodeRemoved(promo_code, plan_title, frequency_title) {
    const eventName = 'Promo Code Removed';
    const props = {
      promo_code: promo_code,
      plan_title: plan_title,
      frequency_title: frequency_title,
    };
    this.segment_event(eventName, props);
  },

  trackSubscriptionCancelled(props) {
    const eventName = 'Cancellation Survey';
    this.segment_event(eventName, props);
  },

  getStarted(props) {
    const eventName = 'Get Started';
    this.segment_event(eventName, props);
  },

  welcomeLead(props) {
    const eventName = 'Welcome Lead';
    this.segment_event(eventName, props);
  },

  expressBoxSelection(props) {
    const eventName = 'Express Box Selection';
    this.segment_event(eventName, props);
  },

  planSelected(props) {
    const eventName = 'Plan Selected';
    this._applyFacebookCookies(props);
    this.segment_event(eventName, props);
  },

  productAddedToBox(props) {
    const eventName = 'Product Added To Box';
    this.segment_event(eventName, props);
  },

  productRemovedFromBox(props) {
    const eventName = 'Product Removed From Box';
    this.segment_event(eventName, props);
  },

  checkoutInitiated(props) {
    const eventName = 'Checkout Initiated';
    this._applyFacebookCookies(props);
    this.segment_event(eventName, props);
  },

  checkoutCompleted(props) {
    const eventName = 'Order Completed';
    this._applyFacebookCookies(props);
    delete props['page'];  // is this still the plan?
    this.segment_event(eventName, props);
  },

  pricingViewed(family_size, logged_in) {
    const eventName = 'Pricing Viewed';
    const props = {
      family_size: family_size,
      logged_in: logged_in,
    };
    this.segment_event(eventName, props);
  },

  getBoxNow(subscription_id, frequency_title, plan_title, promo_code) {
    const eventName = 'Box Ordered Now';
    const props = {
      subscription_id: subscription_id,
      frequency_title: frequency_title,
      plan_title: plan_title,
      promo_code: promo_code,
    };
    this.segment_event(eventName, props);
  },

  trackSkip(subscription_id, frequency_title, plan_title) {
    const eventName = 'Box Skipped';
    const props = {
      subscription_id: subscription_id,
      frequency_title: frequency_title,
      plan_title: plan_title,
    };
    this.segment_event(eventName, props);
  },

  comingSoon(postal_code) {
    const eventName = 'Coming Soon';
    const props = {
      postal_code: postal_code,
    };
    this.segment_event(eventName, props);
  },
  purchasePrepackedBox(order_id, revenue, product_sku, product_id) {

    const isCanada = document.head.querySelector("[property~='og:site_name']").content.includes('trulocal.ca');

    const eventName = 'Purchase Prepacked Box';
    const props = {
      currency: isCanada ? 'CAD' : 'USD',
      revenue: revenue,
      order_id: order_id,
      products: [
        {
          sku: product_sku,
          product_id: product_id
        }
      ],
      // grab the page URL, without the domain
      order_type: window.location.href.toString().split(window.location.host)[1]
    };
    this.segment_event(eventName, props);
  },

  // new events
  viewSearchResults (props) {
    const eventName = "view_search_results";
    this.segment_event(eventName, props);
  },
  viewItem (props) {
    const eventName = "view_item";
    this.segment_event(eventName, props);
  },
  selectItem (props) {
    const eventName = "select_item";
    this.segment_event(eventName, props);
  },
  addToCart (props) {
    const eventName = "add_to_cart";
    this.segment_event(eventName, props);
  },
  viewCart (props) {
    const eventName = "view_cart";
    this.segment_event(eventName, props);
  },
  addPaymentInfo (props) {
    const eventName = "add_payment_info";
    this.segment_event(eventName, props);
  },
  addShippingInfo (props) {
    const eventName = "add_shipping_info";
    this.segment_event(eventName, props);
  },
  checkoutEmailCollected (props) {
    const eventName = "checkoutEmailCollected";
    this.segment_event(eventName, props);
  }
}

export default Segment