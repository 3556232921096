const Modal = {
  $el: $('.modal'),
  $toggler: $('[data-toggle="modal"]'),
  init () {
    let _ = this

    _.$toggler.each(function () {
      let $self = $(this)

      $self.on('click', function () {
        let $target = $($self.data('target'))
        _.show($target)
      })
    })
  },
  show ($target, onshow, ondismiss) {
    let _ = this

    if(typeof $target === 'string' || $target instanceof String) {
      $target = $($target)
    }

    if (onshow === undefined) {
      $target.fadeIn(200).addClass('active')
    } else {
      $target.fadeIn(200, onshow()).addClass('active')
    }

    $target.find('.modal-body').on('click', function () {
      _.hide($target, ondismiss)
    })

    $target.find('.modal-dialog').on('click', function (e) {
      e.stopPropagation()
    })

    $target.find('[data-dismiss="modal"]').on('click', function () {
      _.hide($target, ondismiss)
    })

    if($target.length){
      $('body').addClass('modal-open');
    }

    $('#chat-widget-container').hide();
  },
  hide ($target, ondismiss) {
    if (ondismiss === undefined) {
      $($target).fadeOut(200).removeClass('active')
    } else {
      $($target).fadeOut(200, ondismiss()).removeClass('active')
    }
    $($target).trigger({
      type: 'hide'
    })

    $('#chat-widget-container').show();
    $('body').removeClass('modal-open');
  }
}

export default Modal
