const Multiselect = {
  $el: $('.multiselect'),
  init () {
    let _ = this

    if(_.$el) {
      _.$el.each(function () {
        let $select = $(this)
        let selectArray = $select.val()
        $select.wrap('<div class="multiselect-wrapper" />')
        let $wrapper = $select.parent('.multiselect-wrapper')
        let $dropdown = $(`<ul class="multiselect-dropdown" data-label="${$select.data('label')}" />`)
        let $value = $('<div class="form-multiselect form-control" />')
        let $container = $('<button class="multiselect-container" />')
        let $label = $(`<span class="multiselect-label">${$select.data('label')}</span>`)
        let $indicator = $('<span class="multiselect-indicator" />')

        // Toggle Tag
        function toggleTag(value) {
          if(selectArray.indexOf(value) === -1) {
            addTag(value)
          } else {
            removeTag(value)
          }
        }

        // Create Tag
        function createTag(value, text) {
          let $remove = $('<button class="remove"></button>')
          let $tag = $('<span class="multiselect-tag" data-value="' + value + '">' + text + '</span>')
          $dropdown.find('li[data-value="' + value + '"]').addClass('active')
          $remove.on('click', function () {
            removeTag(value)
            return false
          })
          $tag.append($remove)
          $value.append($tag)
        }

        // Add Tag
        function addTag(value) {
          selectArray.push(value)
          $select.val(selectArray)
          $select.trigger('change')
          checkSelect()
        }

        function removeTag (value) {
          selectArray.splice(selectArray.indexOf(value), 1)
          $select.val(selectArray)
          $select.trigger('change')
          checkSelect()
        }

        // Update multiselect
        function updateMultiselect() {
          let array = $select.val()
          $dropdown.find('li').removeClass('active')
          $value.html('')
          for(let i = 0; i < array.length; i++) {
            let value = array[i]
            let text = $select.find('option[value="' + value + '"]').text()
            createTag(value, text)
            $dropdown.find('li[data-value="' + value + '"]').addClass('active')
          }
        }

        // Check if empty
        function checkSelect () {
          if(selectArray.length > 0) {
            $container.removeClass('empty')
          } else {
            $container.addClass('empty')
          }
        }

        // Build Dropdown
        $select.children('option').each(function () {
          let $option = $(this)
          let value = $option.attr('value')
          let text = $option.text()
          let $item = $('<li data-value="' + value + '"><button><span class="multiselect-dropdown-item-indicator"></span>' + text + '</button></li>')
          $item.find('button').on('click', function () {
            toggleTag(value)
            return false
          })
          $dropdown.append($item)
        })

        // Binds
        $wrapper.on('click', function (e) {
          e.stopPropagation()
        })

        $select.on('change', function () {
          updateMultiselect()
          console.log('MULTISELECT: ', $select.val())
          return false
        })

        $container.on('click', function () {
          if (!$wrapper.hasClass('active')) _.hide()
          $wrapper.toggleClass('active')
          return false
        })

        $container.append($indicator)
        $container.append($label)
        $container.append($value)
        $wrapper.append($container)
        $wrapper.append($dropdown)

        checkSelect()
        updateMultiselect()
      })
    }
  },
  hide () {
    $('.multiselect-wrapper.active').removeClass('active');
  }
}

export default Multiselect
