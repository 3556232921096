/* global TruTranslations */
const ShippingProgress = {

  init() {
    var outerProgressContainer = document.getElementById("outer-progress-container");
    if (!outerProgressContainer) return;
    this.progress = document.getElementById("progress");
    this.stepCircles = document.querySelectorAll(".circle");
    this.order_id = outerProgressContainer.dataset.order_id;
    this.status_url = outerProgressContainer.dataset.status_url;
    this.csrfmiddlewaretoken = document.querySelector('[name=csrfmiddlewaretoken]').value;

    document.getElementById("progress-tracking-container").style.display = "none";

    this.get_shipping_status();
  },

  get_active_circle(status) {
    //NOTE CHANGE HERE TO 1-4
    //1=Processed
    //2=Packed
    //3=Shipped
    //4=Delivered

    if (status === "") {
      return 0;
    } else if (status==="Processed") {
      return 1;
    } else if(status==="Packed") {
      return 2;
    } else if(status === "Shipped") {
      return 3;
    } else if (status === "Delivered") {
      return 4;
    } else {
      // If the status is not expected, assume it is a shipping status that is not "Delivered"
      return 3;
    }

  },

  update(currentActive, status, tracking_number, tracking_link) {
    let _ = this;
    _.stepCircles.forEach((circle, i) => {
      if (i < currentActive) {
        circle.classList.add("prog-active");
      } else {
        circle.classList.remove("prog-active");
      }
    });

    const activeCircles = document.querySelectorAll(".prog-active");
     _.progress.style.width = ((activeCircles.length - 1) / (_.stepCircles.length - 1)) * 100 + "%";

     document.getElementById("progress-label").innerHTML = TruTranslations['progress_label'];
     document.getElementById("progress-status").innerHTML = status in TruTranslations ? TruTranslations[status] : status;

     document.getElementById("tracking_link").href = tracking_link;
     document.getElementById("tracking_link").innerHTML = tracking_number;
     if (currentActive >= 3) {
       document.getElementById("progress-tracking-container").style.display = "flex";
     }

  },

  get_shipping_status() {
    let _ = this
    let request_data = {
      csrfmiddlewaretoken: _.csrfmiddlewaretoken,
      order_id: _.order_id
    }

    $.post(this.status_url, request_data, function(data) {
      console.log(data)

      let currentActive = _.get_active_circle(data.details)
      _.update(currentActive, data.details, data.tracking_number, data.tracking_link);
    });
  },
};


export default ShippingProgress