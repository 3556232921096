const ShippingDate = {

    init() {

    },

    getShippingDate(postal_code, popup_pickup_only=false) {
        let csrftoken = ShippingDate.getCookie('csrftoken');
        let current_utc_date = new Date().toISOString();
        $.ajax({
            url:window.location.protocol+'//'+window.location.host+"/ajax/get_shipping_date",
            type: "POST",
            data_type: 'json',
            data: {'postal_code': postal_code, 'order_date': current_utc_date},
            beforeSend: function(xhr) {
                xhr.setRequestHeader("X-CSRFToken", csrftoken);
            },
            success: function(data) {
                console.log("Success: " + JSON.stringify(data));
                if (data.status == 'success') {
                    $('#box-shipping-date').text(data.shipping_date);
                    if (popup_pickup_only && data.pickup_only) {
                        $('#pickup-only-popup-wrapper').css('display', 'block');
                    }
                }
            },
            error: function () {
                $('#box-shipping-date').text('');
            }
        });
    },

    getCookie(name) {
        var cookieValue = null;
        if (document.cookie && document.cookie !== '') {
            var cookies = document.cookie.split(';');
            for (var i = 0; i < cookies.length; i++) {
                var cookie = cookies[i].trim();
                // Does this cookie string begin with the name we want?
                if (cookie.substring(0, name.length + 1) === (name + '=')) {
                    cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                    break;
                }
            }
        }
        return cookieValue;
    }
}

export default ShippingDate