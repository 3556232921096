import Modal from './modal'

const Cookies = {
  $modal: $('#modal-cookies'),
  $accept: $('[data-accept="cookies"]'),
  init () {
    let _ = this

    if (_.$modal.length) {
      let cookie = Cookies.getCookie('cookies')


      if (cookie !== 'accepted') {
        Modal.show(_.$modal)
      }

      _.$accept.on('click', function () {
        Modal.hide(_.$modal)
        Cookies.setCookie('cookies', 'accepted', 365)
      })
    }
  },
  getCookie (cname) {
    // linear search through list of cookies
    for (const cookie of document.cookie.split('; ')) {
      if (!cookie.startsWith(cname + '=')) continue;
      // save the result as everything after the '=' symbol
      return decodeURIComponent(cookie.split('=')[1]);
    }
    return null;  // cookie name not found
  },
  setCookie (cname, cvalue, exdays) {
    let d = new Date()
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000))
    let expires = `expires=${d.toUTCString()}`
    document.cookie = `${cname}=${cvalue};${expires};path=/`
  }
}

export default Cookies
