let $body = $('body')

const Toast = {
  $el: null,
  template: (type, title, description) => `
    <div class="toast-alert toast-alert-${type}">
      <div class="toast-alert-container container">
        <span class="toast-alert-icon alert-icon alert-icon-${type}"><i class="i i-${type === 'success' ? 'check' : 'cross'}"></i></span>
        <div class="toast-alert-content">
          <h5 class="toast-alert-title">${title}</h5>
          <p class="toast-alert-description">${description}</p>
        </div>
        <span class="toast-alert-timer">
          <svg width="23px" height="23px" viewBox="0 0 23 23">
            <circle class="oval" fill="none" stroke-width="2" cx="11.5" cy="11.5" r="10.5"></circle>
            <circle class="timer" fill="none" stroke-width="2" cx="11.5" cy="11.5" r="10.5"></circle>
          </svg>
        </span>
      </div>
    </div>
  `,
  timer: null,
  time: 0,
  hover: false,
  show (type, title, description) {
    let _ = this

    _.time = 0
    clearInterval(_.timer)

    _.$el = $(_.template(type, title, description))
    $body.append(_.$el)
    _.$el.addClass('visible')
    _.$el.on('click', () => {
      _.hide()
    })
    _.$el.on('mouseover', () => {
      _.hover = true
    })
    _.$el.on('mouseleave', () => {
      _.hover = false
    })

    _.timer = setInterval(() => {
      if (!_.hover && _.time < 5) _.time+=0.5
      else if (_.time >= 5) _.hide()
    }, 500)
  },
  hide () {
    let _ = this

    _.time = 0
    clearInterval(_.timer)

    _.$el.removeClass('visible')
    setTimeout(() => {
      _.$el.remove()
    }, 500)
  }
}

export default Toast
