import Cookies from './cookies'
import Modal from './modal'

const Onboarding = {
  $el: $('.modal-onboarding'),
  init () {
    let _ = this

    if (_.$el.length) {
      let cookie = Cookies.getCookie('onboarding')

      if (cookie !== 'shown') {
        Cookies.setCookie('onboarding', 'shown', 365)
        let $modal = _.$el.eq(0)
        _.showModal($modal)
      }
    }
  },
  showModal ($modal) {
    let _ = this
    Modal.show($modal)

    $modal.find('.modal-prev').on('click', function () {
      let $target = $($modal.data('onboarding-prev'))

      Modal.hide($modal)
      _.showModal($target)
    })

    $modal.find('.modal-next').on('click', function () {
      let $target = $($modal.data('onboarding-next'))

      Modal.hide($modal)
      _.showModal($target)
    })
  }
}

export default Onboarding
