import 'owl.carousel'

const Carousel = {
  Locals: {
    $elPhotos: $('.carousel-locals-photos'),
    $elQuotes: $('.carousel-locals-quotes'),
    init () {
      let _ = this

      if (_.$elQuotes.length) {

        _.$elPhotos.owlCarousel({
          items: 1,
          nav: true,
          loop: true,
          center: true
        })

        _.$elQuotes.owlCarousel({
          items: 1,
          animateOut: 'fadeOut',
          animateIn: 'fadeIn',
          mouseDrag: false,
          touchDrag: false
        })

        _.$elPhotos.on('changed.owl.carousel', function(event) {
          console.log('changed', event.page.index)
          _.$elQuotes.trigger('to.owl.carousel', event.page.index)
        })

      }
    }
  },
  Reviews: {
    $elPhotos: $('.carousel-reviews-photos'),
    $elQuotes: $('.carousel-reviews-quotes'),
    init () {
      let _ = this

      if (_.$elQuotes.length) {

        _.$elPhotos.owlCarousel({
          items: 1,
          nav: false,
          loop: true
        })

        _.$elQuotes.owlCarousel({
          items: 1,
          animateOut: 'fadeOut',
          animateIn: 'fadeIn',
          mouseDrag: false,
          touchDrag: false,
          nav: true,
          loop: true
        })

        _.$elQuotes.on('changed.owl.carousel', function(event) {
          _.$elPhotos.trigger('to.owl.carousel', event.page.index)
        })

        _.$elPhotos.on('changed.owl.carousel', function(event) {
          _.$elQuotes.trigger('to.owl.carousel', event.page.index)
        })

      }
    }
  },
  Testimonials: {
    $el: $('.carousel-testimonials'),
    init () {
      let _ = this

      if (_.$el.length) {
        _.$el.owlCarousel({
          items: 1,
          loop: true,
          dots: true,
          nav: true,
          center: true
        })
      }
    }
  },
  Products: {
    $el: $('.carousel-products'),
    init () {
      let _ = this

      if (_.$el.length) {

        let responsive

        if (!_.$el.hasClass('carousel-products-alternative')) {
          responsive = {
            0: {
              items: 1
            },
            600: {
              items: 2
            },
            900: {
              items: 3
            }
          }
        } else {
          responsive = {
            0: {
              items: 1
            },
            600: {
              items: 2,
              loop: true
            },
            900: {
              items: 3,
              mouseDrag: false,
              touchDrag: false,
              pullDrag: false
            }
          }
        }

        _.$el.on('initialized.owl.carousel', function(event) {
          _.setNavigation($(event.target))
        })

        _.$el.on('resized.owl.carousel', function(event) {
          _.setNavigation($(event.target))
        })

        _.$el.on('loaded.owl.lazy', function(event) {
          _.setNavigation($(event.target))
        })

        _.$el.owlCarousel({
          loop: true,
          dots: false,
          nav: true,
          lazyLoad: true,
          lazyLoadEager: 10,
          responsive
        })
      }
    },
    setNavigation ($item) {
      setTimeout(()=> {
        $item.parent().find('.owl-nav').css('top', `${$($item.find('.product-image')[0]).height()/2}px`).addClass('calculated')
      }, 500)
    }
  },
  Recipes: {
    $el: $('.carousel-recipes'),
    init () {
      let _ = this

      if (_.$el.length) {

        _.$el.owlCarousel({
          loop: true,
          dots: false,
          nav: true,
          responsive: {
            0: {
              items: 1
            },
            600: {
              items: 2
            },
            900: {
              items: 3
            }
          }
        })

      }
    }
  }
}

export default Carousel
