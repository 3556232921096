import 'easy-pie-chart/dist/jquery.easypiechart.min.js'
import 'jquery.appear'

const Chart = {
  $el: $('.chart'),
  init () {
    let _ = this
    let dy = -$(window).height()/6

    if(_.$el.length) {
      _.$el.each(function(){
        let $self = $(this)
        let percent = $(this).data('percent')
        let size = $(this).data('size')
        let width = $(this).data('width')

        $self.attr('data-percent',0)
        $self.easyPieChart({
          barColor: $self.data('bar-color'),
          trackColor: $self.data('track-color'),
          scaleColor: false,
          size: size || 150,
          lineWidth: width || 8,
          onStep: function(from, to, percent) {
            $(this.el).find('.percent').text(Math.round(percent))
          }
        })

        if (!_.$el.data('static') !== true) {
          setTimeout(() => {
            $self.appear(function(){
              $self.data('easyPieChart').update(percent)
            }, { accY: dy })
          }, 1500)
        } else {
          $self.data('easyPieChart').update(percent)
        }
      })
    }
  },
  update (chart, percent, newColor, retries=0) {
    chart.forEach(el => {
      if(el && $(el) && $(el).data('easyPieChart')){
        if (newColor) {
          $(el).data('easyPieChart').options['barColor'] = newColor
        }
        $(el).data('easyPieChart').update(percent)
      }else if(retries < 5){
        setTimeout(() => {
          let retried = retries + 1;
          Chart.update(chart, percent, newColor, retried)
        }, 1000)
      }
    })
  }
}

export default Chart
