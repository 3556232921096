/* global TruTranslations */
const Validation = {

    init() {
        let _ = this;

        // test for postal_code input existence
        if ($( "input[name='postal_code']" ).length){
            $( "#continue-checkout" ).click(function() {
                if (_.checkWelcomePostal() ){
                    $( "#continue-checkout" ).addClass('loading').attr('disabled', true);
                    $( "#continue-checkout span" ).last().text(TruTranslations['validating']);
                    $( "#continue-checkout .i" ).removeClass("i-box");
                    $( "input[name='postal_code']" ).closest("form")[0].submit();
                }
            });
        }
    },

    checkWelcomePostal(){
        if ( this.validatePostalCode( $("input[name='postal_code']").val() ) ) {
            $(".input-error-text").hide();
            $( "input[name='postal_code']" ).removeClass("input-error");
            return true;
        } else {
            $(".input-error-text").show();
            $( "input[name='postal_code']" ).addClass("input-error");
            $( "#continue-checkout" ).removeClass('loading').attr('disabled', false);
            $( "#continue-checkout span" ).last().text("Build Your Box");
            $( "#continue-checkout .i" ).addClass("i-box");
            return false;
        }
    },

    validatePostalCode(userInput) {
        let _ = this;
        if (_.isValidUSZip(userInput) || _.isValidPostalCode(userInput)) {
            return true;
        } else {
            return false;
        }
    },

    isValidUSZip(userInput) {
        return /^\d{5}(-\d{4})?$/.test(userInput);
    },

    isValidPostalCode(userInput) {
        return /^[ABCEGHJKLMNPRSTVXY][0-9][ABCEGHJKLMNPRSTVWXYZ](-| |)[0-9][ABCEGHJKLMNPRSTVWXYZ][0-9]$/
            .test(userInput.toUpperCase());
    },

    getPostalRegion(postal){
        if (postal.match(/^\d/)) return 'IL';
        switch (postal.toLowerCase().substring(0,1)) {
        case 'g':
        case 'h':
        case 'j':
            return 'QC';
        case 'k':
        case 'l':
        case 'm':
        case 'n':
        case 'p':
            return 'ON';
        case 't':
            return 'AB';
        case 'v':
            return 'BC';
        }
        return false;
    },

    postalZone(postal){
        if (postal.match(/^\d/)) return 'IL';
        switch (postal.substring(0,1).toLowerCase()) {
        case 'a':
        case 'b':
        case 'c':
        case 'e':
        case 'g':
        case 'h':
        case 'j':
            return 'NATION';
        case 'k':
        case 'l':
        case 'm':
        case 'n':
        case 'p':
            return 'ON';
        case 't':
            return 'AB';
        case 'v':
            return 'BC';
        }
        return '';
    }
};


export default Validation

