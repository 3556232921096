import 'jquery.appear'
import imagesLoaded from 'imagesloaded'

imagesLoaded.makeJQueryPlugin($)

const Images = {
  $el: $('.bg-image'),
  init () {
    let _ = this
    let loader = $('<span class="loader" />')

    _.$el.each(function(){
      let $self = $(this)
      let src = $self.data('src')
      let highRes = $self.data('src-2x')

      if (highRes && window.devicePixelRatio >= 2) {
        src = src.split('.')
        src[0] += '@2x'
        src = src.join('.')
        $self.css('background-image', `url(${src})`)
      }
      $self.css('background-image', `url(${src})`)
      $self.wrap('<div class="bg-image-container"></div>')
      $self.after(loader.clone())
      $self.removeAttr('data-src').removeAttr('data-src-2x')
      try{
        if (!$self.data('prevent-fade')) {
          $self.imagesLoaded({
            background: true
          }, function() {
            $self.addClass('loaded')
          })
        } else {
          $self.addClass('loaded')
        }
      } catch (err) {
        console.log(err);
      }
    })
  }
}

export default Images