import '../../../node_modules/jquery-custom-select/dist/js/jquery.custom-select.js'

const Forms = {
  Select: {
    $el: $('.custom-select'),
    init () {
      let _ = this

      if (_.$el.length) {
        _.$el.customSelect({
          block: 'select-container'
        }).on('change', function () {
          let value = $(this).val()

          console.log('SELECT:', value);
        })
      }
    }
  }
}

export default Forms
