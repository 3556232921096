/* global Stripe */
/* global Toast */
import Modal from './modal';

const GiftCardCheckout = {
  $modal: $('#checkout-gift-card-modal'),
  stripe: null,
  stripeElements: null,
  card: null,
  init (productId, stripePublishableKey, product_grid) {
    let _ = this

    $('.product-options button', _.$modal).click(function() {
      _.selectProduct($(this).data('productid'), $(this).data('product_grid'));
    });

    $('form [data-step="1"] input', _.$modal).change(function() {
      _.validate(1);
    });
    $('form [data-step="2"] input', _.$modal).change(function() {
      _.validate(2);
    });

    // stripe
    _.stripe = Stripe(stripePublishableKey, { locale: $('html')[0].lang });
    _.stripeElements = _.stripe.elements();

    let style = {
      base: {
        color: '#32325d',
        lineHeight: '18px',
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: 'antialiased',
        fontSize: '16px',
        '::placeholder': {
          color: '#aab7c4'
        }
      },
      invalid: {
        color: '#fa755a',
        iconColor: '#fa755a'
      }
    };
    _.card = _.stripeElements.create("card", { style: style });
    _.card.mount("#checkout-gift-card-modal .card-element");

    // Handle real-time validation errors from the card Element.
    _.card.addEventListener('change', function(event) {
      if (event.error) {
        $('#checkout-gift-card-modal .card-errors').text(event.error.message);
      } else {
        $('#checkout-gift-card-modal .card-errors').text('')
      }
    });

    // Init modal
    _.goToStep(1);
    if (productId) _.selectProduct(productId, product_grid);
    Modal.show(_.$modal);
    _.validate(1);
  },
  init_sale (productId, stripePublishableKey, image, discount, price) {
    let _ = this

    $('.product-options button', _.$modal).click(function() {
      _.displayProduct($(this).data('productid'), $(this).data('image'), $(this).data('discount'), $(this).data('price'));
    });

    $('form [data-step="1"] input', _.$modal).change(function() {
      _.validate(1);
    });
    $('form [data-step="2"] input', _.$modal).change(function() {
      _.validate(2);
    });

    // stripe
    _.stripe = Stripe(stripePublishableKey, { locale: $('html')[0].lang });
    _.stripeElements = _.stripe.elements();

    let style = {
      base: {
        color: '#32325d',
        lineHeight: '18px',
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: 'antialiased',
        fontSize: '16px',
        '::placeholder': {
          color: '#aab7c4'
        }
      },
      invalid: {
        color: '#fa755a',
        iconColor: '#fa755a'
      }
    };
    _.card = _.stripeElements.create("card", { style: style });
    _.card.mount("#checkout-gift-card-modal .card-element");

    // Handle real-time validation errors from the card Element.
    _.card.addEventListener('change', function(event) {
      if (event.error) {
        $('#checkout-gift-card-modal .card-errors').text(event.error.message);
      } else {
        $('#checkout-gift-card-modal .card-errors').text('')
      }
    });

    // Init modal
    _.goToStep(1);
    if (productId) _.displayProduct(productId, image, discount, price);
    Modal.show(_.$modal);
    _.validate(1);
  },
  selectProduct (productId) {
    let _ = this

    $('.product-options button', _.$modal).removeClass('btn-primary').addClass('btn-outline-primary');
    $('.product-options button[data-productid="' + productId + '"]', _.$modal).removeClass('btn-outline-primary').addClass('btn-primary');
    $('form input[name="product_id"]', _.$modal).val(productId);
  },
  displayProduct (productId, image, discount, price) {
    let _ = this;

    $('#modal_picture', _.$modal).attr("src", image);
    $('#modal_discount', _.$modal).text(discount);
    $('#modal_price', _.$modal).text(price);
    $('form input[name="product_id"]', _.$modal).val(productId);
  },
  validate (step) {
    let _ = this

    var isValid = true;
    $('form [data-step="' + step + '"] input[required]', _.$modal).each(function() {
      if ($(this).val().length == 0) {
        isValid = false;
      }
    });
    if (isValid) {
      $('form [data-step="' + step + '"] .submit-btn', _.$modal).removeAttr('disabled');
    } else {
      $('form [data-step="' + step + '"] .submit-btn', _.$modal).attr('disabled', true);
    }
  },
  goToStep (step) {
    let _ = this

    $(".modal-navigation a.current", _.$modal).removeClass('current');
    $('.modal-navigation a[data-step="' + step + '"]', _.$modal).addClass('current');
    $(".modal-step", _.$modal).removeClass('current');
    $('.modal-step[data-step="' + step + '"]', _.$modal).addClass('current');
  },
  getProductId () {
    let _ = this

    return $('form input[name="product_id"]', _.$modal).val();
  },
  getProductLabel () {
    let _ = this

    return $('.product-options button[data-productid="' + _.getProductId() + '"]', _.$modal).data('productlabel');
  },
  submitCustomerInformation () {
    let _ = this

    var sources = ['billing_first_name', 'billing_last_name', 'recipient_first_name', 'recipient_last_name', 'recipient_email', 'note'];

    for (var i = 0; i < sources.length; i++) {
      var value = $('#checkout-gift-card-modal form [name="' + sources[i] + '"]').val();
      $('#checkout-gift-card-modal [data-source="' + sources[i] + '"]').html(value);
    }
    var productLabel = _.getProductLabel();
    $('#checkout-gift-card-modal [data-source="product"]').html(productLabel);
    var notifyRecipient = $('#checkout-gift-card-modal form [name="notify_recipient"]').is(':checked') ? 'Yes' : 'No';
    $('#checkout-gift-card-modal [data-source="notify_recipient"]').html(notifyRecipient);

    _.validate(2);
    _.goToStep(2);
  },
  clearModal () {
    let _ = this

    $('.alert-error .alert-content', _.$modal).html('');
    $('.alert-error', _.$modal).hide();
    $('form .submit-btn', _.$modal).removeClass('loading').removeAttr('disabled');

    $('form input:not([type="checkbox"])', _.$modal).val('');
    $('form textarea', _.$modal).val('');
    $('[data-source]', _.$modal).html('');
  },
  submitPayment () {
    let _ = this

    let form = $("form", _.$modal)
    $('.submit-btn', form).addClass('loading').attr('disabled', true);

    _.stripe.createToken(_.card).then(function(result) {
      if (result.error) {
        // Inform the customer that there was an error.
        $(".card-errors", _.$modal).text(result.error.message)
        $('.submit-btn', form).removeClass('loading').removeAttr('disabled');
      } else {
        // Send the token to your server.
        $(form).append('<input type="hidden" name="stripeToken" value="' + result.token.id + '" />')
        // $(form).submit();
        $.post($(form).attr('action'), $(form).serialize(), function(data) {
          if (data.status == 'success') {
            Toast.show('success', 'Gift certificate purchased', 'Your gift certificate has been purchased successfully, you or the recipient will recieve an email confirmation.');
            _.clearModal()
            Modal.hide(_.$modal);
          } else {
            var errorMessage = data.errors.join('<br/>');
            $('.alert-error .alert-content', _.$modal).html(errorMessage);
            $('.alert-error', _.$modal).show();
          }
          $('.submit-btn', form).removeClass('loading').removeAttr('disabled');
        }, "json");
      }
    });
  },
}

export default GiftCardCheckout
