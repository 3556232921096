const Filters = {
  $toggle: $('[data-toggle="filters"]'),
  init () {
    let _ = this

    _.$toggle.on('click', function () {
      let $self = $(this)

      $self.toggleClass('open')
      $self.next('.builder-filters-content').slideToggle(300)
    })
  },
  reset () {
    $('.builder-filters-content').removeAttr('style')
  }
}

export default Filters
