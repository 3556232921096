/* global CURRENT_LANGUAGE */

const Utils = {
    formatCurrency(value, decimals = 2) {
        if (typeof(value) == 'string' && CURRENT_LANGUAGE === 'fr') value = value.replace(',', '.')
        let v = parseFloat(value).toFixed(decimals);
        if (v.search(/[1-9]/g) < 0) v = 0.0.toFixed(decimals);
        if (CURRENT_LANGUAGE === 'fr') {
            v = v.replace('.', ',')
            return v + " $";
        } else
            return "$" + v;
    },

    lockProvinces(provinces) {
        let options = document.getElementById('province').options;
        for (let i = options.length - 1 ; i >= 0 ; i--){
            if (provinces.includes(options[i].value)) {
                options[i].hidden = false;
                options[i].selected = true;
            } else {
                options[i].hidden = true;
            }
        }
    },

    async canShip(postalCode, csrf_token) {
        const resp = await fetch('/can_ship/' + postalCode.replace(' ',''), {
            method: 'post',
            headers: {
                'Accept': 'application/json',
                'X-Requested-With': 'XMLHttpRequest',
                'X-CSRFToken': csrf_token,
            },
        });
        return resp.json();
    }
}

export default Utils
