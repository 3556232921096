/* global CURRENT_LANGUAGE */

import flatpickr from "flatpickr";
import { French } from "flatpickr/dist/l10n/fr.js"

const DatePicker = {
  init () {
    let data = {minDate: $('.date_input').attr('min'), maxDate: $('.date_input').attr('max')};
    if (CURRENT_LANGUAGE == "fr") {
      flatpickr.localize(flatpickr.l10ns.fr);
      data['locale'] = French;
    }
    flatpickr($('.date_input'), data);
  }
}

export default DatePicker
