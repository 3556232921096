import Isotope from 'isotope-layout'

class PrepackedGrid {
  constructor() {
    this.iso = null;
  }

  init(){
    let self = this;
    this.iso = new Isotope(`#builder .builder-grid`, {
      itemSelector: `#builder .builder-grid-item`,
      percentPosition: true,
      masonry: {
        columnWidth: `#builder .builder-grid-item`
      }
    })

    $('.builder-grid').imagesLoaded().progress(() => {
      this.iso.layout()
    })

    this.iso.on('arrangeComplete', (filteredItems) => {
      if (!filteredItems.length) $('.builder-empty').fadeIn(200)
      else $('.builder-empty').fadeOut(200)
    })

    $('.builder-filters').find('select').on('change', function(e) {
      let $self = $(e.target)
      let filter = $self.data('filter')
      let value = $self.val()
      self.filterList(filter, value)
    })
    
    $(window).resize(() => {
      setTimeout(() => {
        this.iso.layout()
      }, 200)
    });
  }

  filterList(filter, value){
    this.iso.arrange({
      filter: function() {
        if(value.length === 0){
          return true
        }else{
          let product = $(this).find('.product-card')[0];
          let valid = 0;
          if(filter == "type"){
            valid = (value.includes($(product).data('type'))) ? 1 : 0
          }else if(filter == "price"){
            valid = (value.includes($(product).data('price'))) ? 1 : 0
          }
          return valid;
        }
      }
    })
  }
}

export default PrepackedGrid;